import MinusIcon from "@public/images/kbpay/minus_Icon.svg";
import PlusIcon from "@public/images/kbpay/plus_icon.svg";
import MinusDisableIcon from "@public/images/kbpay/minus_disable_Icon.svg";
import PlusDisableIcon from "@public/images/kbpay/plus_disable_icon.svg";
import IcInfo from "@public/images/common/ic_info_fi_g20.svg";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "next-i18next";
export default function PeopleCard({
  idx,
  uuid,
  data,
  removeRoomHandle,
  peopleAddHandle
}) {
  const {
    t
  } = useTranslation("common");
  const addAdultCntHandler = (e, addCnt) => {
    e.stopPropagation();
    peopleAddHandle(uuid, {
      adultCnt: data.adultCnt + addCnt,
      childs: data.childs
    });
  };
  const addChildCntHandler = (e, addCnt) => {
    e.stopPropagation();
    if (addCnt > 0) {
      peopleAddHandle(uuid, {
        adultCnt: data.adultCnt,
        childs: [...data.childs, {
          cid: uuidv4(),
          age: ""
        }]
      });
    } else if (addCnt < 0) {
      peopleAddHandle(uuid, {
        adultCnt: data.adultCnt,
        childs: [...data.childs.slice(0, data.childs.length - 1)]
      });
    }
  };
  const changeChildAge = (e, cid, age) => {
    e.stopPropagation();
    let list = [...data.childs];
    list.map(info => {
      if (info.cid === cid) {
        info.age = age;
        return false;
      }
    });
    peopleAddHandle(uuid, {
      adultCnt: data.adultCnt,
      childs: list
    });
  };
  return <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 rounded-lg">
      <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pl-6 py-[13px]
                   tablet:px-[16px]">
        <p className="w-[327px] text-lg font-bold text-left text-Gray-900">
          {t("pay.guest.information.room", {
          n: idx
        })}
        </p>
        {idx > 1 && <div onClick={e => {
        e.stopPropagation();
        removeRoomHandle(uuid);
      }} className="flex justify-end items-center flex-grow-0 flex-shrink-0">
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 h-[22px] relative gap-2.5 px-1 py-[9px] cursor-pointer">
              <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#999]">
                {t("searchbar.btn.room.remove")}
              </p>
            </div>
          </div>}
      </div>
      <div className="flex items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pl-6 py-1 bg-white
                   tablet:px-[16px]">
        <p className="w-[254px] text-sm font-bold text-left text-Gray-900 tablet:w-[-webkit-fill-available]">
          {t("searchbar.txt.room.adult")}
        </p>
        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative">
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-2.5 p-1 cursor-pointer" onClick={e => {
          if (data.adultCnt > 1) {
            addAdultCntHandler(e, -1);
          }
        }}>
            {data.adultCnt === 1 ? <MinusDisableIcon /> : <MinusIcon />}
          </div>
          <p className="flex-grow-0 flex-shrink-0 w-[50px] text-base font-bold text-center text-[#222]">
            {data.adultCnt}
          </p>
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-2.5 p-1 cursor-pointer" onClick={e => {
          if (data.adultCnt < 8) {
            addAdultCntHandler(e, +1);
          }
        }}>
            {data.adultCnt < 8 ? <PlusIcon /> : <PlusDisableIcon />}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0">
        <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pl-6 py-1 bg-white
                     tablet:px-[16px]">
          <p className="w-[254px] text-sm text-left tablet:w-[-webkit-fill-available]">
            <span className="flex-grow w-[254px] text-sm font-bold text-left text-Gray-900">
              {t("searchbar.txt.room.child")}{" "}
            </span>
            <span className="flex-grow w-[254px] text-sm text-left text-Gray-500">
              {t("searchbar.txt.room.child.age.under")}
            </span>
          </p>
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative">
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-2.5 p-1 cursor-pointer" onClick={e => {
            if (data.childs.length > 0) {
              addChildCntHandler(e, -1);
            }
          }}>
              {data.childs.length === 0 ? <MinusDisableIcon /> : <MinusIcon />}
            </div>
            <p className="flex-grow-0 flex-shrink-0 w-[50px] text-base font-bold text-center text-[#222]">
              {data.childs.length}
            </p>
            <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-2.5 p-1 cursor-pointer" onClick={e => {
            if (data.childs.length < 8) {
              addChildCntHandler(e, +1);
            }
          }}>
              {data.childs.length < 8 ? <PlusIcon /> : <PlusDisableIcon />}
            </div>
          </div>
        </div>
        {data.childs && data.childs.length > 0 && <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-2 pl-6
                       tablet:px-[16px]">
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-0.5">
              <IcInfo />
              <p className="flex-grow-0 flex-shrink-0 w-[310px] text-xs text-left text-[#999]">
                {t("searchbar.txt.room.children.age.selected")}
              </p>
            </div>
            <div className="flex flex-wrap justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-[8px]">
              {data.childs.map((info, index) => <div key={index} className={`relative flex justify-center items-center flex-grow-0 flex-shrink-0 w-[192px] overflow-hidden gap-2.5 px-[12px] py-[10px] rounded-[8px] bg-white border border-Gray-200
                             tablet:w-[calc(50%_-_4px)]`} onClick={e => {
            e.stopPropagation();
          }}>
                  <select className="flex flex-col justify-center items-start self-stretch flex-grow relative h-[37px] pt-[20px] pl-[4px] rounded-none border-none appearance-none
                               bg-[url('/images/common/ic_arrow_down_ou_g90.svg')] bg-no-repeat bg-[center_right] bg-white font-bold" defaultValue={info.age} onChange={e => {
              changeChildAge(e, `${info.cid}`, e.target.value);
            }}>
                    <option value="">
                      {t("searchbar.txt.room.children.age.select")}
                    </option>
                    {[...Array(18)].map((age, idx) => <option key={idx} value={idx}>
                        {idx === 0 ? t("searchbar.txt.room.child.age.under.select", {
                  n: idx === 0 ? 1 : idx
                }) : t("searchbar.txt.room.child.age.select", {
                  n: idx === 0 ? 1 : idx
                })}
                      </option>)}
                  </select>
                  <label className="absolute left-[16px] top-[8px] text-Gray-300 transition-all ease-[ease] duration-[.3s] text-xs">
                    {t("searchbar.txt.room.child")}
                    {index + 1}
                  </label>
                </div>)}
            </div>
          </div>}
      </div>
    </div>;
}