import { ConvertLocale } from "../utils/ConvertLocale";
import { getAccessToken } from "../utils/AccessToken";
import {axiosSetting} from "@modules/client/ApiAxios";

/**
 * Web Main
 * */
export async function getWebMain(locale) {
  const res = await fetch("/api/www/manifest", {
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 메인 - 검색 배너 목록
 * */
export async function getMainSearch(locale) {
  const res = await fetch("/api/www/main/search", {
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 검색 - 북마크한 호텔 조회
 * */
export async function getBookmarkHotels(locale, body) {
  const res = await fetch(`/api/search/bookmark/hotels`, {
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
    body: JSON.stringify(body),
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 검색 - 피드검색 메인
 * */
export async function getFeedMain(locale) {
  const res = await fetch("/api/search/feed-main", {
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 검색 - 호텔 / 지역 조회
 * {
 *     "query": "서울",
 *     "offset": 0,
 *     "limit": 10,
 *     "indices": [
 *         {
 *             "name": "hotels"
 *         },
 *         {
 *             "name": "regions"
 *         }
 *     ]
 * }
 * */
export async function getSearchMeta(locale, body) {
  const res = await fetch("/api/search/meta", {
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
    body: JSON.stringify(body),
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 검색 - HashTag 조회
 * {
 *     "query": "서울",
 *     "limit": 10,
 * }
 * */
export async function getSearchHashtags(locale, body) {
  const res = await fetch("/api/search/hashtags", {
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
    body: JSON.stringify(body),
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 검색 - 검색 메타 필터
 * */
export async function getSearchFilter(locale) {
  const res = await fetch("/api/search/filter", {
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 검색 - 좌표 기반 호텔 조회
 * {
 *     "offset": 0,
 *     "limit": 100,
 *     "top_left": {
 *         "lat": 37.67994286674389,
 *         "lon": 126.89121343195438
 *     },
 *     "bottom_right": {
 *         "lat": 36.36726156943901,
 *         "lon": 127.37328781485559
 *     },
 *     "check_in": "2022-08-22",
 *     "check_out": "2022-08-23",
 *     "rooms": [
 *         {
 *             "no": 0,
 *             "adults": 2,
 *             "children": [8]
 *         }
 *     ],
 *     "house_category": ["1", "2"],
 *     "star_rating": [5,4,3],
 *     "crew_pick": [1],
 *     "sort": "DEFAULT"
 * }
 * */
export async function getSearchGeoPoint(locale, body) {
  const res = await fetch("/api/search/geo-point/hotels", {
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
      "x-tbz-app-platform": "PC",
    },
    body: JSON.stringify(body),
  });
  return { status: res.status, body: await res.json() };
}

/**
 * 검색 - 지역 기반 호텔 검색
 * {
 *     "offset": 0,
 *     "limit": 100,
 *     "check_in": "2022-08-22",
 *     "check_out": "2022-08-23",
 *     "rooms": [
 *         {
 *             "no": 0,
 *             "adults": 2,
 *             "children": [8]
 *         }
 *     ],
 *     "house_category": ["1", "2"],
 *     "star_rating": [5,4,3],
 *     "crew_pick": [1],
 *     "sort": "DEFAULT"
 * }
 * */
export async function getSearchRegion(locale, regionId, body) {
  const res = await fetch(`/api/search/regions/${regionId}/hotels`, {
    method: "POST",
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
      "x-tbz-app-platform": "PC",
    },
    body: JSON.stringify(body),
  });
  return { status: res.status, body: await res.json() };
}

export async function getSearchItem(locale, region_id) {
  const res = await fetch(`/api/www/main/search/item?region_id=${region_id}`, {
    headers: {
      Authorization: getAccessToken(),
      "Accept-Language": ConvertLocale(locale),
    },
  });
  return { status: res.status, body: await res.json() };
}

export async function getSearchItemTheme(
  locale,
  region_id,
  travel_companion_type,
  theme_category_id
) {
  const res = await fetch(
    `/api/www/main/search/item/theme?region_id=${region_id}&travel_companion_type=${travel_companion_type}&theme_category_id=${theme_category_id}`,
    {
      headers: {
        Authorization: getAccessToken(),
        "Accept-Language": ConvertLocale(locale),
      },
    }
  );
  return { status: res.status, body: await res.json() };
}

export async function getServerSearchInfo (req) {
  const res = await fetch(axiosSetting.server() + `/v1/search/info`, {
    method: "GET",
    headers: {
      Authorization: getAccessToken(),
      "x-tripbtoz-channel": 'KBPAY',
      "Content-Type": "application/json",
      "Accept-Language": req.headers["accept-language"],
      "User-Agent": req.headers["user-agent"],
      'X-UA-Timezone': 'Asia/Seoul',
    },
  });
  return res.json();
}