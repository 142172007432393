export default function RoundedSqBtn({
  onClick,
  text = '',
  type = '',
  disabled,
  className = '',
  children
}) {
  const tintedText = 'text-Primary600';
  const clearBorderColor = 'border-Gray-300';
  const clearTextColor = 'text-Gray-900';
  const grayColor = 'bg-Gray-900';
  const rounded = 'rounded-lg';
  return <>
      {type.includes('basic') && <button type="button" onClick={onClick} disabled={disabled} className={`flex items-center justify-center ${rounded} bg-Primary500 ${className} ${disabled && 'bg-Gray-200 text-Gray-300'}`}>
          {text}
        </button>}

      {type.includes('clear') && <button type="button" onClick={onClick} className={`flex items-center justify-center border border-solid ${rounded} ${clearBorderColor} ${clearTextColor} ${className}`}>
        {text}
         {children && children}
      </button>}
      {type.includes('nonBorder') && <button type="button" onClick={onClick} className={`flex items-center justify-center rounded-lg ${className}`}>
            {text}
          </button>}

      {type.includes('tinted') && <button type="button" onClick={onClick} className={`flex items-center justify-center rounded-lg ${tintedText} bg-Primary100 ${className}`}>
          {text}
        </button>}
      {type.includes('blackGray') && <button type="button" onClick={onClick} disabled={disabled} className={`flex items-center justify-center rounded-lg text-white  ${grayColor} ${className}`}>
          {text}
        </button>}
      </>;
}