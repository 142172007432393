import PeopleCard from "./PeopleCard";
import { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "next-i18next";
import RoundedSqBtn from "@components/Button/RoundedSqBtn";
import { useKbpay } from "@modules/hooks/useDomain";
export default function RoomCard({
  roomsBoxRef,
  isRoomBoxFocus,
  defaultRoomInfo,
  roomBoxCloseHandler,
  roomSelectCompleteHandle
}) {
  const {
    t
  } = useTranslation("common");
  const [roomInfos, setRoomInfos] = useState([]);
  const isKBPay = useKbpay();
  const roomCardRef = useRef();
  const bodyClickHandler = useCallback(event => {
    if (roomsBoxRef) {
      if (!roomsBoxRef.current.contains(event.target) && !roomCardRef.current.contains(event.target)) {
        roomSelectCompleteHandle(defaultRoomInfo);
        if (roomBoxCloseHandler) roomBoxCloseHandler();
      }
    }
  }, []);
  useEffect(() => {
    setRoomInfos(JSON.parse(JSON.stringify(defaultRoomInfo)));
    document.body.addEventListener("click", bodyClickHandler);
    return () => {
      document.body.removeEventListener("click", bodyClickHandler);
    };
  }, []);
  useEffect(() => {
    if (isRoomBoxFocus) {
      setRoomInfos(JSON.parse(JSON.stringify(defaultRoomInfo)));
    }
  }, [isRoomBoxFocus]);
  const [disabledBtn, setDisabledBtn] = useState(false);
  useEffect(() => {
    roomSelectCompleteHandle(roomInfos);
    setDisabledBtn(roomInfos?.map(room => room.people.childs?.map(child => child.age)).filter(info => info.filter(age => age === "").length > 0).length > 0);
  }, [roomInfos]);
  const addRoomHandle = () => {
    if (Object.keys(roomInfos).length < 8) {
      setRoomInfos([...roomInfos, {
        uuid: uuidv4(),
        people: {
          adultCnt: 2,
          childs: []
        }
      }]);
    }
  };
  const removeRoomHandle = uuid => {
    let data = [];
    roomInfos.map(info => {
      if (info.uuid !== uuid) {
        data.push(info);
      }
    });
    setRoomInfos(data);
  };
  const peopleAddHandle = (uuid, roomInfoData) => {
    let data = [...roomInfos];
    data.map(info => {
      if (info.uuid === uuid) {
        info.people = roomInfoData;
        return false;
      }
    });
    setRoomInfos(data);
  };
  return <div ref={roomCardRef} className={`absolute right-0 top-[72px] mt-[12px] flex flex-col justify-start items-start flex-grow-0 flex-shrink-0 overflow-hidden pt-3.5 rounded-lg bg-white shadow-[0px_8px_20px_0_rgba(0,0,0,0.08)] tablet:shadow-[none]
                  tablet:w-full tablet:h-[calc(100%_-_72px)] tablet:mt-0 tablet:pt-0 select-none
                  ${isRoomBoxFocus ? "block" : "hidden"}`} onClick={e => {
    e.stopPropagation();
  }}>
      <div className="flex flex-col justify-start items-end flex-grow-0 flex-shrink-0 max-h-[440px] w-[440px] relative overflow-y-auto
                   tablet:w-full tablet:max-h-[calc(100%_-_80px)]">
        {roomInfos?.map((info, index) => <PeopleCard key={index} idx={index + 1} uuid={info.uuid} data={info.people} removeRoomHandle={removeRoomHandle} peopleAddHandle={peopleAddHandle} />)}
      </div>

      <div className={'flex justify-between w-[440px] gap-[8px] px-6 pt-4 pb-6 bg-white tablet:absolute tablet:w-full tablet:px-[16px] tablet:bottom-0'}>
        <RoundedSqBtn type='clear' onClick={e => {
        e.stopPropagation();
        addRoomHandle();
      }} className={`B_16_100_Bold p-[16px_18px] w-1/2 ${isKBPay && 'border-Gray-500'}`} text={t("searchbar.btn.room.add")} />
        <RoundedSqBtn type='basic' onClick={() => {
        if (!disabledBtn) roomSelectCompleteHandle(roomInfos, true);
      }} disabled={disabledBtn} className={`B_16_100_Bold p-[16px_18px] w-1/2`} text={t("searchbar.btn.rooms.add")} />
      </div>
    </div>;
}