import { Cookies } from "react-cookie-consent";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import {convertDomain, getDomain} from "./AccessToken";
import {getServerSearchInfo} from "@modules/api/Search";
import {setCookie} from "cookies-next";

export function setSearchDateCookie(startDate, endDate) {
  if (startDate) {
    Cookies.set("check_in", dayjs(startDate).format("YYYYMMDD"), {
      expires: 1,
      domain: getDomain(),
    });
  } else {
    Cookies.set("check_in", null, { expires: -1, domain: getDomain() });
  }
  if (endDate) {
    Cookies.set("check_out", dayjs(endDate).format("YYYYMMDD"), {
      expires: 1,
      domain: getDomain(),
    });
  } else {
    Cookies.set("check_out", null, { expires: -1, domain: getDomain() });
  }
}

export function setSearchRoomsCookie(roomInfo) {
  Cookies.set("room_info", JSON.stringify(roomInfo), {
    expires: 1,
    domain: getDomain(),
  });
}
export function getSearchRoomsCookie() {
  return Cookies.get("room_info", { domain: getDomain() })
    ? JSON.parse(Cookies.get("room_info", { domain: getDomain() }))
    : [
      {
        uuid: uuidv4(),
        people: { adultCnt: 2, childs: [] },
      },
    ];
}

export function getSearchDateCookie() {
  return {
    startDate: Cookies.get("check_in", { domain: getDomain() })
      ? dayjs(
        Cookies.get("check_in", { domain: getDomain() }),
        "YYYYMMDD"
      ).toDate()
      : dayjs().add(7, "days").toDate(),
    endDate: Cookies.get("check_out", { domain: getDomain() })
      ? dayjs(
        Cookies.get("check_out", { domain: getDomain() }),
        "YYYYMMDD"
      ).toDate()
      : dayjs().add(8, "days").toDate(),
  };
}

export async function getSeverSearchDefaultSettingDate (context) {
  const {req, res, query} = context
  const domain = convertDomain(req.headers["host"]);

  const date = {
    startDate :dayjs().add(7, "days").format("YYYYMMDD"),
    endDate : dayjs().add(8, "days").format("YYYYMMDD")
  }

  if(context.query["check-in"]) date.startDate = context.query["check-in"].replace(/[^0-9]/gi, "")
  if(context.query["check-out"]) date.endDate = context.query["check-out"].replace(/[^0-9]/gi, "")

  if(!context.query["check-in"]) {
    const info = await getServerSearchInfo(req)
    console.log(info)
    if(info.item.search_info) {
      date.startDate = dayjs(info.item.search_info.check_in).format('YYYYMMDD')
      date.endDate = dayjs(info.item.search_info.check_out).format('YYYYMMDD')
    }
  }

  setCookie("check_in", date.startDate, {
    req,
    res,
    maxAge: 60 * 60 * 24,
    domain
  });

  setCookie("check_out", date.endDate, {
    req,
    res,
    maxAge: 60 * 60 * 24,
    domain
  });

  return { checkIn : date.startDate, checkOut : date.endDate }
}



export function getRoomQuery(roomInfo) {
  let roomQuery = {};
  roomQuery["rooms"] = roomInfo.length;
  roomInfo.map((info, index) => {
    roomQuery["room-" + index + "-adults"] = info.people.adultCnt;
    roomQuery["room-" + index + "-children"] = info.people.childs.length;
    info.people.childs.map((info, index2) => {
      roomQuery["room-" + index + "-child-" + index2 + "-age"] = Number(info.age);
    });
  });
  return roomQuery;
}
